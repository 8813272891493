import { createGlobalStyle, css } from "styled-components"

import "fontsource-open-sans"
import PPMori from "../static/fonts/PPMori-Regular.otf"

const sizes = {
  mobile: `320`,
  mobileL: `375`,
  tablet: `425`,
  laptop: `768`,
  laptopL: `1024`,
  desktop: `1440`,
  desktopL: `1920`,
  "4k": `2560`,
}

export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `
  return acc
}, {})

const colors = {
  text: "#0f172a",
}

const fonts = {
  body: "Open Sans, sans-serif",
  title: "PPMori, sans-serif",
}

const fontSizes = {
  body: "21px",
  h1: "6.25rem",
}

const fontSizesMobile = {
  body: "20px",
  h1: "4.25rem",
}

const spacer = "3rem"

export const theme = {
  colors,
  fonts,
  fontSizes,
  fontSizesMobile,
  spacer,
}

export const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'PPMori';
  src: url(${PPMori}) format('opentype');
  font-style: normal;
  font-display: auto;
}

html {
  box-sizing: border-box;
  overscroll-behavior: none;
}
*, *:before, *:after {
  box-sizing: inherit;
}

::-moz-selection {
  background: #f5f5f5;
}

::selection {
  background: #f5f5f5;
}

body {
  margin: 0;
  word-wrap: break-word;
  font-kerning: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "kern", "liga", "clig", "calt";
  -ms-font-feature-settings: "kern", "liga", "clig", "calt";
  -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
  font-feature-settings: "kern", "liga", "clig", "calt";
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  overflow-y: scroll;
}
body {
  background: rgb(131,58,180);
  background: linear-gradient(146deg, rgba(131,58,180,0.23015143557422968) 5%, rgba(253,29,29,0.041075805322128844) 62%, rgba(252,176,69,0.352000175070028) 100%);
  color: ${colors.text};
  font-family: ${fonts.body};
  font-weight: 400;
  ${media.tablet`
    font-size: ${fontSizes.body}
  `}
  font-size: ${fontSizesMobile.body}
}
section {
  padding-bottom: ${spacer};
}
h1 {
  ${media.tablet`
    font-size: ${fontSizes.h1}
  `}
  font-size: ${fontSizesMobile.h1}
}
h1, h2, h3, h4, h5, h6 {
  text-rendering: optimizeLegibility;
  font-family: ${fonts.title};
  letter-spacing: 0px;
  font-weight: 600;
}
p {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
a {
  text-decoration: underline;
}
a, a:hover, a:visited {
  color: ${colors.text}
}
a, 
ul {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  list-style-position: outside;
  list-style-image: none;
}
ol {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: ${spacer};
  list-style-position: outside;
  list-style-image: none;
}
ul {
  list-style: none;
}
ol li {
  padding-left: 0;
}
ul li {
  padding-left: 0;
}
`
