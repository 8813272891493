import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { GlobalStyle, media, theme } from "../styles"

const S = {}

S.Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 960px;
  min-height: 100vh;
  padding: 2rem;
  margin: 0;
  ${media.laptop`
    margin: 0 5rem 0 5rem;
    padding: 3rem;
  `}
`

S.Main = styled.main``

S.Background = styled.div`
  background-image: url("background.svg");
`

S.Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
`

S.Nav = styled.nav`
  position: static;

  ${media.tablet`
    position: fixed;
  `}

  top: 1rem;
  right: 1rem;
`
S.Li = styled.li`
  text-align: right;
  padding: 5px;
`

const Navigation = () => {
  return (
    <S.Nav>
      <ul>
        <S.Li>
          <Link to="/">Home</Link>
        </S.Li>
        <S.Li>
          <Link to="/speaking/">Speaking</Link>
        </S.Li>
      </ul>
    </S.Nav>
  )
}

const Layout = ({ children }) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <S.Background>
          <Navigation />
          <S.Container>
            <S.Main>{children}</S.Main>
            <S.Footer>
              <div>
                <span aria-label="peace sign" role="img">
                  &#9996;
                </span>
                <span>© {new Date().getFullYear()}</span> &#8226;{" "}
                <span>This site uses cookies to analyze traffic.</span>
              </div>
              <div></div>
            </S.Footer>
          </S.Container>
        </S.Background>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
